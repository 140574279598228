import {createRouter, createWebHashHistory} from 'vue-router';

const routes = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home', //首页
    name: 'home',
    component: () => import ('../views/Home.vue'),
    meta: {
      title: '首页',
      requireAuth: true, // 判断是否需要登录
    },
  },
  {
    path: '/kjDetails', //矿机详情
    name: 'kjDetails',
    component: () => import('../views/kjDetails.vue'),
    meta: {
      title: '矿机详情',
      requireAuth: true, // 判断是否需要登录
    },
  },
  // {
  //   path: '/ethDetails', //ETH详情
  //   name: 'ethDetails',
  //   component: () => import('../components/home/ethDetails.vue'),
  //   meta: {
  //     title: '第二期详情',
  //     requireAuth: true, // 判断是否需要登录
  //   },
  // },
  // {
  //   path: '/ipfsDetails', //IPFS详情
  //   name: 'ipfsDetails',
  //   component: () => import ('../components/home/ipfsDetails.vue'),
  //   meta: {
  //     title: '第四期详情',
  //     requireAuth: true, // 判断是否需要登录
  //   },
  // },
  // {
  //   path: '/mill', //矿机
  //   name: 'mill',
  //   component: () => import('../views/Mill.vue'),
  //   meta: {
  //     title: '矿机',
  //     requireAuth: true, // 判断是否需要登录
  //   },
  // },
  {
    path: '/kjList', //矿机列表
    name: 'kjList',
    component: () => import('../views/kjList.vue'),
    meta: {
      title: '矿机列表',
      requireAuth: true, // 判断是否需要登录
    },
  },
  {
    path: '/millList', //矿机列表
    name: 'millList',
    component: () => import('../components/mill/millList.vue'),
    meta: {
      title: '我的矿机列表',
      requireAuth: true, // 判断是否需要登录
    },
  },
  {
    path: '/more', //了解更多
    name: 'more',
    component: () => import('../components/more.vue'),
    meta: {
      title: '了解更多',
      requireAuth: false, // 判断是否需要登录
    },
  },
  {
    path: '/my', //我的
    name: 'my',
    component: () => import ('../views/My.vue'),
    meta: {
      title: '我的',
      requireAuth: true, // 判断是否需要登录
    },
  },
  {
    path: '/setting', //设置
    name: 'setting',
    component: () => import('../views/setting.vue'),
    meta: {
      title: '设置',
      requireAuth: true, // 判断是否需要登录
    },
  },
  {
    path: '/login', //登陆
    name: 'login',
    component: () => import('../components/Login/index.vue'),
    meta: {
      title: '登录',
    },
  },
  {
    path: '/sign', //注册
    name: 'sign',
    component: () => import('../components/sign/index.vue'),
    meta: {
      title: '注册',
    },
  },
  {
    path: '/realName', //我的-实名认证
    name: 'realName',
    component: () => import ('../components/my/realName.vue'),
    meta: {
      title: '实名认证',
      requireAuth: true, // 判断是否需要登录
    },
  },
  {
    path: '/feedBack', //我的-联系客服
    name: 'feedBack',
    component: () => import ('../components/my/feedBack.vue'),
    meta: {
      title: '联系客服',
      requireAuth: true, // 判断是否需要登录
    },
  },
  {
    path: '/Withdrawal', //我的-提现
    name: 'Withdrawal',
    component: () => import ('../views/Withdrawal.vue'),
    meta: {
      title: '提现',
      requireAuth: true, // 判断是否需要登录
    },
  },
  {
    path: '/MentionRecord', //我的-交易记录
    name: 'MentionRecord',
    component: () => import ('../views/MentionRecord.vue'),
    meta: {
      title: '交易记录',
      requireAuth: true, // 判断是否需要登录
    },
  },
  {
    path: '/electricity', //我的-rmb交易记录
    name: 'electricity',
    component: () => import ('../components/my/electricity.vue'),
    // component: () => import ('../components/my/feedBack.vue'),
    meta: {
      title: '交易记录',
      requireAuth: true, // 判断是否需要登录
    },
  },
  {
    path: '/MentionRecordDetail', //我的-交易详情
    name: 'MentionRecordDetail',
    component: () => import ('../views/MentionRecordDetail.vue'),
    meta: {
      title: '详情',
      requireAuth: true, // 判断是否需要登录
    },
  },
];

const router = createRouter ({
  history: createWebHashHistory (),
  routes,
});

export default router;
