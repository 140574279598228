<template>
  <div class="footer">
    <van-tabbar v-model="active" class="active_tab" active-color="#333" inactive-color="#999">
      <van-tabbar-item
        v-for="(item,index) in tabArr"
        :key="index"
        replace
        :to="item.url"
        @change="onChange"
      >
        <span>{{item.name}}</span>
        <template #icon="props">
          <img style="width:26px;height:26px;" :src="props.active ? item.icon.active : item.icon.inactive" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  name: "footer",
  watch: {
    $route() {
      this.setTabActive();
    }
  },
  data() {
    return {
      active: 0,
      tabArr: [
        {
          name: "首页",
          url: "/home",
          icon: {
            active:
              "https://bit24k.s3.ap-northeast-1.amazonaws.com/img/home_1.png",
            inactive:
              "https://bit24k.s3.ap-northeast-1.amazonaws.com/img/home_2.png",
          }
        },
        {
          name: "矿机",
          url: "/kjList",
          icon: {
            active:
              "https://bit24k.s3.ap-northeast-1.amazonaws.com/img/kj_1.png",
            inactive:
              "https://bit24k.s3.ap-northeast-1.amazonaws.com/img/kj_2.png",
          }
        },
        // {
        //   name: "算力套餐",
        //   url: "/kjList",
        //   icon: {
        //     active:
        //       "https://alipic.lanhuapp.com/SketchPngb7224f8bc837faf56eccd75de4472ea1eb23216a359540075952d883235837ea",
        //     inactive:
        //       "https://alipic.lanhuapp.com/SketchPng7e6842f19d4855ecd78ca0ef661a46bebcf1570cf3415548e90e2619ccd1474c"
        //   }
        // },
        // {
        //   name: "订单",
        //   url: "/kjList",
        //   icon: {
        //     active:
        //       "https://alipic.lanhuapp.com/SketchPnge1ed88c953ab393f0f4b8cc610f3df278d32590c8920e4e8dc5eee46208ffbf1",
        //     inactive:
        //       "https://alipic.lanhuapp.com/SketchPng6dcb54b04295401b1e44dda94f09e5e7bde207fe81ca084aebfcfbe3d70578d1"
        //   }
        // },
        {
          name: "我的",
          url: "/my",
          icon: {
            active:
              "https://bit24k.s3.ap-northeast-1.amazonaws.com/img/my_1.png",
            inactive:
              "https://bit24k.s3.ap-northeast-1.amazonaws.com/img/my_2.png",
          }
        }
      ]
    };
  },
  created() {
    this.setTabActive();
  },
  methods: {
    setTabActive() {
      this.active = this.tabArr.findIndex(
        item => item.url === this.$route.path
      );
    },
    onChange(val) {
      // console.log(index, props);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.footer {
  position: fixed;
  bottom: 0;
  width: 375px;
  height: 60px;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px -4px 15px 0px rgba(158, 158, 158, 0.15);
  // border-top: 1px solid #f1f1f1;
  color: #999999;
  font-size: 12px;
}
</style>
