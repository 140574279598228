import axios from 'axios';
// import {Notify} from 'vant';

const getToken = () => {
  //...
  return localStorage.getItem ('token');
  // return '8305009f-31a9-4813-acd0-0e4dea399373';
};
const reFetchToken = () => {};

if (process.env.NODE_ENV) {
  // console.log (process.env.NODE_ENV);
}
const service = axios.create ({
  baseURL: '/kj',
  timeout: 600000,
  headers: {'X-Custom-Header': 'foobar'},
});

// 请求拦截器
service.interceptors.request.use (
  config => {
    // 在发送请求之前做些什么

    const token = getToken ();
    if (token) {
      config.headers['token'] = token;
    } else {
      // reFetchToken ();
    }

    return config;
  },
  error => {
    // 对请求错误做些什么
    return Promise.reject (error);
  }
);

// 响应拦截器
service.interceptors.response.use (
  response => {
    // 对响应数据做点什么
    // {code: 200, message: 'asdfadf', data: []}

    const {code, data} = response;
    // console.log (code, data); //bug
    if (response.code === 200) {
      return data;
    } else if (response.code === 1018) {
      this.$router.push ('/login');
    } else if (code === 404) {
      // Notify ('该页面不存在');
      Promise.reject ({message: '该页面不存在'});
    } else if (code === 403) {
      reFetchToken ();
      // 未登录
      // Notify ('未登录');
      Promise.reject ({message: '未登录'});
    } else if (code > 500) {
      // Notify ('出错了，请重试');
      Promise.reject ({message: '出错了，请重试'});
    }
    return response.data;
  },
  error => {
    // 对响应错误做点什么

    return Promise.reject (error);
  }
);

export default app => {
  app.config.globalProperties.$request = service;
};

export {service};
