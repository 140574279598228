import {service} from '@/utils/request';

const localUserinfo = localStorage.getItem ('USERINFO') || '{}';
const localTokeninfo = localStorage.getItem ('TOKENINFO') || '{}';

export default {
  namespaced: false,
  state () {
    return {
      tokeninfo: JSON.parse (localTokeninfo),
      userinfo: JSON.parse (localUserinfo),
    };
  },
  mutations: {
    SET_USERINFO (state) {
      state.userinfo;
    },
    SET_TOKENINFO (state) {
      state.tokeninfo;
    },
  },
  actions: {
    getCode ({commit}, paylod) {
      const {
        appid,
        redirect_uri,
        scope = 'snsapi_userinfo',
        state = '',
      } = paylod;
      service
        .get (
          `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_uri}&scope=${scope}&state=${state}&response_type=code#wechat_redirect`
        )
        .then (console.log);
    },
    getAccessToken ({commit}, paylod) {
      return new Promise ((resolve, reject) => {
        service
          .get (`https://api.weixin.qq.com/cgi-bin/token`, {
            params: paylod,
          })
          .then (
            res => {
              // this.commentsList = res;
              commit ('SET_TOKENINFO', res);
              // 执行成功的回调函数
              resolve (res);
            },
            error => {
              console.log (error);
              reject (error);
              // 执行失败的回调函数
            }
          );
      });
    },
  },
};
